@use '@angular/material' as mat;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@include mat.core();

$md-quorumdefault: (
  50: #e1ebf3,
  100: #b3cee1,
  200: #80adce,
  300: #4d8cba,
  400: #2773ab,
  500: #015a9c,
  600: #015294,
  700: #01488a,
  800: #013f80,
  900: #002e6e,
  A100: #9dbdff,
  A200: #6a9bff,
  A400: #3778ff,
  A700: #1e67ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$md-quorumaccent: (
  50: #eef9e8,
  100: #d4efc5,
  200: #b8e59f,
  300: #9bdb79,
  400: #85d35c,
  500: #70cb3f,
  600: #68c639,
  700: #5dbe31,
  800: #53b829,
  900: #41ac1b,
  A100: #e9ffe2,
  A200: #c2ffaf,
  A400: #9bff7c,
  A700: #87ff63,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($md-quorumdefault, 700);
$my-accent: mat.define-palette($md-quorumaccent, 500, A100, A400);
$my-warn: mat.define-palette(mat.$red-palette, 600);

$my-custom-typography: mat.define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $display-4: mat.define-typography-level(112px, 112px, 300),
  $display-3: mat.define-typography-level(56px, 56px, 400),
  $display-2: mat.define-typography-level(45px, 48px, 400),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 32px, 400),
  $title: mat.define-typography-level(20px, 32px, 500),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(15px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  $input: mat.define-typography-level(16px, 1.125, 400),
);

/* #endregion */

/* #region Material Overrides */
.mat-form-field-empty {
  font-size: 14px !important;
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.mat-raised-button {
  border-radius: 24px !important;
}

.mat-tab-header {
  z-index: 2 !important;
}

.mat-dialog-container {
  border-radius: 12px;
}

.no-underline > div > div.mat-form-field-underline {
  display: none !important;
}
.no-underline > div > div.mat-form-field-flex > div {
  border-top: 0 !important;
}

/* #endregion */

/* #region Base Variables */
:root {
  --secondary-color: rgba(255, 255, 255, 0.7);
}

/* #endregion */

/* #region Light Theme */
.light-theme {
  $my-primary: mat.define-palette($md-quorumdefault, 700);
  $my-accent: mat.define-palette($md-quorumaccent, 500, A100, A400);
  $my-warn: mat.define-palette(mat.$red-palette, 600);
  $my-light-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
      ),
    )
  );

  @include mat.all-component-themes($my-light-theme);

  --secondary-color: rgba(0, 0, 0, 0.54);

  .mat-drawer-container {
    background-color: #fff;
  }
  .mat-toolbar {
    background-color: #fff;
  }
}
/* #endregion */

/* #region Dark Theme */
.dark-theme {
  $my-primary: mat.define-palette($md-quorumdefault, 400);
  $my-accent: mat.define-palette($md-quorumaccent, 500, A100, A400);
  $my-warn: mat.define-palette(mat.$red-palette, 600);
  $my-dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
      ),
    )
  );

  @include mat.all-component-colors($my-dark-theme);

  --secondary-color: rgba(255, 255, 255, 0.7);

  .mat-tab-label-container {
    background: #212121;
  }
}
/* #endregion */

/* #region Accessibility */
.increase-contrast {
  .mat-list-base .mat-subheader,
  .mat-form-field-appearance-legacy .mat-form-field-label,
  .mat-input-element:disabled,
  .mat-select-disabled .mat-select-value,
  .mat-form-field-hide-placeholder .mat-select-placeholder,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(var(--secondary-color), 0);
  }

  .secondary-text {
    color: rgba(var(--secondary-color), 0);
  }
}

@include mat.core($my-custom-typography);

.large-text {
  $large-typography: mat.define-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $display-4: mat.define-typography-level(120px, 120px, 300),
    $display-3: mat.define-typography-level(59px, 59px, 400),
    $display-2: mat.define-typography-level(48px, 51px, 400),
    $display-1: mat.define-typography-level(38px, 42px, 400),
    $headline: mat.define-typography-level(28px, 34px, 400),
    $title: mat.define-typography-level(24px, 36px, 500),
    $subheading-2: mat.define-typography-level(18px, 28px, 400),
    $subheading-1: mat.define-typography-level(17px, 24px, 400),
    $body-2: mat.define-typography-level(16px, 24px, 500),
    $body-1: mat.define-typography-level(16px, 20px, 400),
    $caption: mat.define-typography-level(14px, 20px, 400),
    $button: mat.define-typography-level(16px, 14px, 500),
    $input: mat.define-typography-level(18px, 1.125, 400),
  );

  @include mat.core($large-typography);
}

.bold-text {
  $bold-typography: mat.define-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $display-4: mat.define-typography-level(112px, 112px, 400),
    $display-3: mat.define-typography-level(56px, 56px, 500),
    $display-2: mat.define-typography-level(45px, 48px, 500),
    $display-1: mat.define-typography-level(34px, 40px, 600),
    $headline: mat.define-typography-level(24px, 32px, 500),
    $title: mat.define-typography-level(20px, 32px, 600),
    $subheading-2: mat.define-typography-level(16px, 28px, 500),
    $subheading-1: mat.define-typography-level(15px, 24px, 500),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $body-1: mat.define-typography-level(14px, 20px, 500),
    $caption: mat.define-typography-level(12px, 20px, 500),
    $button: mat.define-typography-level(14px, 14px, 600),
    $input: mat.define-typography-level(16px, 1.125, 500),
  );
  @include mat.core($bold-typography);
}

.bold-text.large-text {
  $bold-large-typography: mat.define-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $display-4: mat.define-typography-level(120px, 120px, 400),
    $display-3: mat.define-typography-level(59px, 59px, 500),
    $display-2: mat.define-typography-level(48px, 51px, 500),
    $display-1: mat.define-typography-level(38px, 42px, 600),
    $headline: mat.define-typography-level(28px, 34px, 500),
    $title: mat.define-typography-level(24px, 36px, 600),
    $subheading-2: mat.define-typography-level(18px, 28px, 500),
    $subheading-1: mat.define-typography-level(17px, 24px, 500),
    $body-2: mat.define-typography-level(16px, 24px, 600),
    $body-1: mat.define-typography-level(16px, 20px, 500),
    $caption: mat.define-typography-level(14px, 20px, 500),
    $button: mat.define-typography-level(16px, 14px, 600),
    $input: mat.define-typography-level(18px, 1.125, 500),
  );
  @include mat.core($bold-large-typography);
}
/* #endregion */

/* #region Utility Classes */
.secondary-text {
  color: var(--secondary-color);
}

/* #endregion */

html,
body {
  height: 100%;
  margin: 0;
}

@media screen and (max-width: 599px) {
  .responsive-dialog {
    height: 100vh;
    width: 100vw;
    max-width: 100vw !important;
  }

  .responsive-dialog .mat-dialog-container {
    border-radius: 0 !important;
  }

  .responsive-dialog .mat-dialog-container .mat-dialog-content {
    max-height: initial !important;
  }
}

button {
  outline: none !important;
}

@layer base {
  a {
    @apply text-blue-900;
    @apply underline;
  }
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/OpenSans-VariableFont_wdth\,wght.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Catamaran';
  src: url('./assets/Catamaran-VariableFont_wght.ttf');
}

@media print {
  body * {
    visibility: hidden;
  }

  @page {
    size: 215.9mm 279.4mm;
    height: 279.4mm;
    width: 215.9mm;
  }

  .table-container,
  .table-container * {
    visibility: visible;
  }

  .table-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: #ffffff;
  }
}
